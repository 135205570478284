<template>
  <!-- address START -->
  <div class="account-address">
    <!-- address:headline START -->
    <div
      class="account-address-headline"
      v-html="$t('account.address.standardheadline')"
    ></div>
    <!-- address:headline END -->

    <!-- address:standard START -->
    <div class="account-address-standard">
      <div class="inner">
        <p
          v-if="user"
          v-html="userName"
        ></p>
        <span
          v-if="!user"
          class='f-like-text-loading-small'
        ></span>
        <p
          v-if="user"
          v-html="userAddress"
        ></p>
        <span
          v-if="!user"
          class='f-like-text-loading-middle'
        ></span>
        <p
          v-if="user"
          v-html="userCity"
        ></p>
        <span
          v-if="!user"
          class='f-like-text-loading-small'
        ></span>
      </div>
      <router-link
        class="edit"
        :to="'/account/personal-data'"
      ></router-link>
    </div>
    <!-- address:standard END -->
  </div>
  <!-- address END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'addresses',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.address.headline'),

      /**
       * User
       */
      user: null,
      userName: '',
      userAddress: '',
      userCity: '',
    };
  },
  async created() {
    this.user = await this.FETCH_USER();

    if (this.user) {
      this.userName = `${this.user.firstName} ${this.user.lastName}`;
      this.userAddress = `${this.user.street} ${this.user.houseNumber}`;
      this.userCity = `${this.user.zip} ${this.user.city}`;
    }
  },
  methods: {
    async FETCH_USER() {
      await this.$store.dispatch('setUser');
      return this.$store.getters.user;
    },
  },
};
</script>
